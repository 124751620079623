"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.maybeAssignComputedForegroundColor = exports.generateStyleStringFromVEVars = exports.generateStyleStringFromObject = exports.applyTextWidgetSizeRules = exports.applyTextSpacingRules = exports.generateTextWidgetSelector = void 0;
var css_1 = require("@vanilla-extract/css");
var dynamic_1 = require("@vanilla-extract/dynamic");
var SMALL_BREAKPOINT = 200;
var LARGE_BREAKPOINT = 768;
var SMALLEST_DESKTOP_SIZE = 16;
var LARGEST_DESKTOP_SIZE = 100;
var SMALLEST_MOBILE_SIZE = 16;
var LARGEST_MOBILE_SIZE = 35;
var smallestPrintSizeVar = "var(--smallest-print-size, 8)";
var LARGEST_PRINT_SIZE = 60;
var DESKTOP_DIFF = LARGEST_DESKTOP_SIZE - SMALLEST_DESKTOP_SIZE;
var MOBILE_DIFF = LARGEST_MOBILE_SIZE - SMALLEST_MOBILE_SIZE;
var PRINT_DIFF = "(".concat(LARGEST_PRINT_SIZE, " - ").concat(smallestPrintSizeVar, ")");
var BREAKPOINT_DIFF = LARGE_BREAKPOINT - SMALL_BREAKPOINT;
var prepareResponsiveFontSizeRules = function (fontSizeCssVar) {
    var _a;
    var smallSize = "".concat(SMALLEST_MOBILE_SIZE, " + (").concat(MOBILE_DIFF, " * (").concat(fontSizeCssVar, " - ").concat(SMALLEST_DESKTOP_SIZE, "))/").concat(DESKTOP_DIFF);
    var printSize = "calc((".concat(smallestPrintSizeVar, " + ").concat(PRINT_DIFF, " * (").concat(fontSizeCssVar, " - ").concat(SMALLEST_DESKTOP_SIZE, ")/").concat(DESKTOP_DIFF, ") * 1px)");
    return {
        main: {
            fontSize: "calc((".concat(smallSize, ") * 1px)"),
            "@media": (_a = {},
                _a["screen and (min-width: ".concat(SMALL_BREAKPOINT, "px)")] = {
                    fontSize: "calc((".concat(smallSize, ") * 1px + (").concat(fontSizeCssVar, " - (").concat(smallSize, ")) * ((100vw - ").concat(SMALL_BREAKPOINT, "px) / ").concat(BREAKPOINT_DIFF, "))"),
                },
                _a["screen and (min-width: ".concat(LARGE_BREAKPOINT, "px)")] = {
                    fontSize: "calc(".concat(fontSizeCssVar, " * 1px)"),
                },
                _a),
        },
        print: {
            fontSize: printSize,
        },
    };
};
var userStyledTextWidgetScopes = [
    ".columns",
    ".quote-header",
    ".plan-section__title",
    ".plan-card__feature-list",
    ".video-header",
    ".content",
    ".content-wrapper",
];
var generateTextWidgetSelector = function (textWidgetSelector, parentSelector) {
    return userStyledTextWidgetScopes
        .map(function (scope) { return "".concat(parentSelector !== null && parentSelector !== void 0 ? parentSelector : "", " .project-block ").concat(scope, " ").concat(textWidgetSelector); })
        .join(",");
};
exports.generateTextWidgetSelector = generateTextWidgetSelector;
function applyTextSpacingRules(selector, spacingShim, lineHeight, blockSpacingVars, fontWeight) {
    var paddingRule = "".concat(blockSpacingVars.before, " 0 ").concat(blockSpacingVars.after, " 0");
    var styles = {
        letterSpacing: 0,
        lineHeight: lineHeight,
        padding: paddingRule,
        fontWeight: fontWeight,
    };
    (0, css_1.globalStyle)(".project-block ".concat(selector), styles);
    (0, css_1.globalStyle)(".project-block ".concat(spacingShim), {
        padding: paddingRule,
    });
    (0, css_1.globalStyle)(".project-block ".concat(spacingShim, " ").concat(selector), {
        padding: 0,
    });
    (0, css_1.globalStyle)("".concat(spacingShim, ":first-child"), {
        paddingTop: 0,
    });
    (0, css_1.globalStyle)(".project-block table ".concat(selector), {
        padding: 0,
        margin: 0,
    });
    (0, css_1.globalStyle)(".project-block table ".concat(spacingShim), {
        padding: 0,
        margin: 0,
    });
}
exports.applyTextSpacingRules = applyTextSpacingRules;
var applyTextWidgetSizeRules = function (selector, fontSizeCssVar) {
    var responsiveFontSizes = prepareResponsiveFontSizeRules(fontSizeCssVar);
    (0, css_1.globalStyle)((0, exports.generateTextWidgetSelector)(selector), __assign({}, responsiveFontSizes.main));
    (0, css_1.globalStyle)((0, exports.generateTextWidgetSelector)(selector, "#print"), responsiveFontSizes.print);
    (0, css_1.globalStyle)((0, exports.generateTextWidgetSelector)(selector, ".print-legacy"), responsiveFontSizes.print);
};
exports.applyTextWidgetSizeRules = applyTextWidgetSizeRules;
var generateStyleStringFromObject = function (style) {
    return Object.entries(style).reduce(function (style, _a) {
        var _b = __read(_a, 2), key = _b[0], val = _b[1];
        return "".concat(style, " ").concat(key, ": ").concat(val, ";");
    }, "");
};
exports.generateStyleStringFromObject = generateStyleStringFromObject;
var generateStyleStringFromVEVars = function (vars) {
    return (0, exports.generateStyleStringFromObject)((0, dynamic_1.assignInlineVars)(vars));
};
exports.generateStyleStringFromVEVars = generateStyleStringFromVEVars;
var maybeAssignComputedForegroundColor = function (_a) {
    var _b;
    var computeForegroundColor = _a.computeForegroundColor, foregroundStyle = _a.foregroundStyle, foregroundColorVar = _a.foregroundColorVar;
    if (!computeForegroundColor) {
        return undefined;
    }
    return (0, dynamic_1.assignInlineVars)((_b = {}, _b[foregroundColorVar] = computeForegroundColor(foregroundStyle.color.abs), _b));
};
exports.maybeAssignComputedForegroundColor = maybeAssignComputedForegroundColor;
