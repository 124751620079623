"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeFontWeight = exports.isFontWeightVariant = exports.toLabel = exports.getFontInfo = exports.extractFontFromFontStack = exports.generateCSSForFontSelections = exports.getFontSelections = exports.getFontFamily = exports.defaultFontWeightMap = exports.brandFontCSSVarMap = void 0;
var CaptionText_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/CaptionText.css");
var Heading1_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/Heading1.css");
var Heading2_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/Heading2.css");
var Heading3_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/Heading3.css");
var Heading4_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/Heading4.css");
var Paragraph_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/Paragraph.css");
var SmallText_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/SmallText.css");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var dynamic_1 = require("@vanilla-extract/dynamic");
var TEXT_ELEMENTS = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "li",
    "table",
    ".quote-section",
    ".quote-total",
    "blockquote",
];
var FONT_WEIGHT_NAME_MAPPING = {
    "100": "Thin",
    "200": "Extra Light",
    "300": "Light",
    "400": "Regular",
    "500": "Medium",
    "600": "Semi Bold",
    "700": "Bold",
    "800": "Extra Bold",
    "900": "Black",
};
exports.brandFontCSSVarMap = {
    h1: Heading1_css_1.brandHeading1FontFamily,
    h2: Heading2_css_1.brandHeading2FontFamily,
    p: Paragraph_css_1.brandParagraphFontFamily,
};
exports.defaultFontWeightMap = {
    h1: Heading1_css_1.defaultHeading1FontWeight,
    h2: Heading2_css_1.defaultHeading2FontWeight,
    h3: Heading3_css_1.defaultHeading3FontWeight,
    h4: Heading4_css_1.defaultHeading4FontWeight,
    p: Paragraph_css_1.defaultParagraphFontWeight,
    smallText: SmallText_css_1.defaultSmallTextFontWeight,
    captionText: CaptionText_css_1.defaultCaptionTextFontWeight,
};
var supportedFallbackTypefaces = ["sans-serif", "serif", "monospace"];
function getFontFamily(font) {
    var typeface = supportedFallbackTypefaces.includes(font.typeface) ? font.typeface : "sans-serif";
    return "'".concat(font.name, "', ").concat(typeface);
}
exports.getFontFamily = getFontFamily;
function getFontSelections(fonts) {
    var selections = [
        {
            font: fonts.h1,
            tag: "h1",
            targetVariable: Heading1_css_1.brandHeading1FontFamily,
        },
        {
            font: fonts.h2,
            tag: "h2",
            targetVariable: Heading2_css_1.brandHeading2FontFamily,
        },
        {
            font: fonts.p,
            tag: "p",
            fallback: true,
            targetVariable: Paragraph_css_1.brandParagraphFontFamily,
        },
    ];
    if (fonts.h2) {
        selections.push({ tag: "h3", font: fonts.h2, targetVariable: Heading2_css_1.brandHeading2FontFamily });
        selections.push({ tag: "h4", font: fonts.h2, targetVariable: Heading2_css_1.brandHeading2FontFamily });
    }
    if (fonts.p) {
        selections.push({
            tag: "p",
            className: SmallText_css_1.smallTextWidget,
            font: fonts[commonTypes_1.TextElementType.P],
            targetVariable: Paragraph_css_1.brandParagraphFontFamily,
        });
        selections.push({
            tag: "p",
            className: CaptionText_css_1.captionTextWidget,
            font: fonts[commonTypes_1.TextElementType.P],
            targetVariable: Paragraph_css_1.brandParagraphFontFamily,
        });
    }
    return selections;
}
exports.getFontSelections = getFontSelections;
function generateCSSForFontSelections(fontSelections, generateSpecifiedSelectors, generateFallbackSelectors, baseSelectors) {
    var e_1, _a, e_2, _b;
    var _c;
    var css = [];
    var fallbackFont = (_c = fontSelections.find(function (s) { return s.fallback; })) === null || _c === void 0 ? void 0 : _c.font;
    var remainingTargets = new Set(TEXT_ELEMENTS);
    try {
        for (var fontSelections_1 = __values(fontSelections), fontSelections_1_1 = fontSelections_1.next(); !fontSelections_1_1.done; fontSelections_1_1 = fontSelections_1.next()) {
            var selection = fontSelections_1_1.value;
            var tag = selection.tag, font = selection.font, className = selection.className;
            var selector = tag;
            if (className) {
                selector = "".concat(selector, ".").concat(className);
                remainingTargets.delete(className);
            }
            else {
                remainingTargets.delete(tag);
            }
            var selectors = generateSpecifiedSelectors(selector).join(", ");
            css.push("".concat(selectors, " {  font-family: ").concat(getFontFamily(font), ";  }"));
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (fontSelections_1_1 && !fontSelections_1_1.done && (_a = fontSelections_1.return)) _a.call(fontSelections_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    css.push(".qwilr-font, .user-font .qwilr-font, .user-font .calibre { font-family: 'calibre-legacy', sans-serif !important; }");
    var fontVariables = (0, dynamic_1.assignInlineVars)(fontSelections.reduce(function (acc, s) {
        acc[s.targetVariable] = getFontFamily(s.font);
        return acc;
    }, {}));
    css.push(":root { ".concat(fontVariables, " }"));
    if (!fallbackFont)
        return css.join(" ");
    try {
        for (var remainingTargets_1 = __values(remainingTargets), remainingTargets_1_1 = remainingTargets_1.next(); !remainingTargets_1_1.done; remainingTargets_1_1 = remainingTargets_1.next()) {
            var selector = remainingTargets_1_1.value;
            var selectors = __spreadArray(__spreadArray([], __read(generateFallbackSelectors(selector)), false), __read(baseSelectors), false).join(", ");
            css.push("".concat(selectors, " { font-family: ").concat(getFontFamily(fallbackFont), " !important; }"));
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (remainingTargets_1_1 && !remainingTargets_1_1.done && (_b = remainingTargets_1.return)) _b.call(remainingTargets_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return css.join(" ");
}
exports.generateCSSForFontSelections = generateCSSForFontSelections;
function extractFontFromFontStack(fontStack) {
    if (!fontStack)
        return undefined;
    return fontStack.split(",")[0];
}
exports.extractFontFromFontStack = extractFontFromFontStack;
function getFontInfo(styleValue, brandStore) {
    if (styleValue.fontStyleType === "Brand") {
        var brandFontRef = styleValue.brandFontRef;
        return { iFont: brandStore.fonts[brandFontRef], brandFontRef: brandFontRef };
    }
    return { iFont: styleValue.iFont, brandFontRef: null };
}
exports.getFontInfo = getFontInfo;
function toLabel(variant) {
    if (variant in FONT_WEIGHT_NAME_MAPPING) {
        return FONT_WEIGHT_NAME_MAPPING[variant];
    }
    return variant;
}
exports.toLabel = toLabel;
function isFontWeightVariant(variant) {
    return /^\d+$/.test(variant);
}
exports.isFontWeightVariant = isFontWeightVariant;
function normalizeFontWeight(variant) {
    var inverseMapping = new Map(Object.entries(FONT_WEIGHT_NAME_MAPPING).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return [value.toLowerCase(), key];
    }));
    if (inverseMapping.has(variant.toLowerCase())) {
        return inverseMapping.get(variant.toLowerCase());
    }
    if (variant === "normal") {
        return "400";
    }
    return variant;
}
exports.normalizeFontWeight = normalizeFontWeight;
